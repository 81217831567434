<template>
  <div class="ibox shops">
    <div class="ibox-title">
      <h2>{{ $t('shop.payouts.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-md">
        <div class="col p-xs" v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</div>
        <div class="col buttons">
          <a class="btn btn-white" @click="onRefreshClick">
            <i class="fa fa-refresh"></i>
          </a>
        </div>
      </div>
    
        <b-table 
            outlined striped
            :items="payouts"
            :fields="listFields"
            responsive
            ref="listItems">

          <template v-slot:cell(details)="row">
            <a href="javascript:void(0)" @click="row.toggleDetails" :class="{'opened':row.detailsShowing,'toggleDetails':true}">
              Details <i class="fa fa-chevron-down"></i>
            </a>
          </template>
          <template v-slot:row-details="row">
            <div class="orders">
              <div v-for="order in row.item.orders" :key="order._id" class="card order">
                <h4>Commande ({{order._id}})</h4>
                <ul>
                  <li>Contact: {{order.contact.firstname}} {{order.contact.lastname}}</li>
                  <li>Email: {{order.contact.email.email}}</li>
                  <li>Montant: {{formatPriceAmount(order.totalAmount)}}</li>
                </ul>
                
              </div>
            </div>
          </template>        
          
        </b-table>

    </div>
  </div>
</template>

<style scoped>
  .col.buttons {
    text-align: right;
  }

  a.toggleDetails i:last-child {
        margin-left:5px;
        transition: 0.3s ease all;
    }

    a.toggleDetails.opened i:last-child {
        transform: rotate(180deg);
    }

    .orders {
      display:flex;
    }

    .card.order {
      padding:5px 10px;
    }
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';

import { CurrencyCodes } from '@igotweb-node-api/api/modules/payment/src/models/bean/Currency';
import { formatDay, FormatDateType } from '@igotweb-node-api-utils/formatter';

import { usePayoutsAdmin } from '../../../composables/usePayoutsAdmin';

@Component({
  components: { 
    
  }
})
export default class ListPayouts extends Vue {

  setup(props:any, context:any) {
    const { payouts } = usePayoutsAdmin(props, context);
    return { payouts }
  }
  
  showNumberOfRows = false;
  
  get numberOfRows():number {
    return this.payouts.value.length;
  }

  itemToRemove:any = {};
  itemToUpdate:any = {};

  listLoading:boolean = false;
  listItemsSelected = [];

  listFields = [
      /*{
        key: "select",
        label: "",
        class: (this.isMultiSelectAllowed ? "" : "hidden"),
      },*/
      {
        key: "payout.id",
        label: ""
      },
      {
        key: "payout.arrival_date",
        label: "",
        formatter: (value:number, key:any, item:any) => {
          return formatDay(new Date(value*1000), this.currentLanguageCode, {
            type: FormatDateType.LONG
          });
        }
      },
      {
        key: "payout.amount",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return this.formatPriceAmount({
            amount:value / 100,
            nonDiscountedAmount:value /100,
            currencyCode: CurrencyCodes.EUR
          });
        }
      },
      {
        key: "payout.status",
        label: "",
        formatter: this.payoutStatusFormatter()
      },
      {
        key:"details",
        label:""
      }
    ];

  mounted() {
    this.listFields[0].label = this.$t('shop.payouts.headers.id') as string;
    this.listFields[1].label = this.$t('shop.payouts.headers.arrival_date') as string;
    this.listFields[2].label = this.$t('shop.payouts.headers.amount') as string;
    this.listFields[3].label = this.$t('shop.payouts.headers.status') as string;

    this.enableAutoRefresh();
  }

  payoutStatusFormatter() {
    return (value:any, key:any, item:any) => {
      return this.$t('shop.payouts.payoutStatus.'+value) as string;
    }
  }

  enableAutoRefresh() {
    setTimeout(() => {
        // @ts-ignore
        this.$refs.listItems.refresh()
        this.enableAutoRefresh();
    }, 5000*60);
  }

  onRefreshClick() {
    // @ts-ignore
    this.$refs.listItems.refresh()
  }

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  showShopPage(shop:any) {
    this.$router.push("/"+this.currentLanguageCode+"/shop/"+shop._id).catch(err => {});
  }

}
</script>