import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { Price } from '@fwk-client/modules/shop/vue';
import { useShopAdmin } from "./useShopAdmin";

interface RevenuesAdminInput {
}

export function useRevenuesAdmin(props:RevenuesAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  const filters:any = reactive({
    startDate: '',
    endDate: ''
  })

  var isListLoading:Ref<boolean> = ref(false);
  
  const revenues:Ref<Price|undefined> = ref(undefined);
  const nbOrders:Ref<number|undefined> = ref(undefined);

  const updateRevenues = async () => {

    var input = {
      ...filters
    }

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/payment/revenues', input);
      if(response.revenues != undefined) {  
        revenues.value = response.revenues;
        if(response.nbOrders != undefined) {
          nbOrders.value = response.nbOrders
        }
      }
      else {
        revenues.value = undefined;
        nbOrders.value = undefined;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateRevenues();

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateRevenues();
    },
    { deep: false }
  )

  return {
    filters,
    revenues,
    nbOrders,
    updateRevenues
  }
  
}