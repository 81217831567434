<template>
  <validation-provider :name="inputId" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label :for="inputId" :class="labelCSSClass">{{ inputLabel }}</label>
      <div :class="fieldCSSClass">
        <input :id="inputId" :type="inputType" :placeholder="inputPlaceholder" class="form-control" v-model="input" :required="required" :disabled="disabled" />
        <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class DateInput extends mixins<GenericInput<Date>>(GenericInput) {

  @Prop({
    type: Boolean,
    required: false
  }) readonly disabled!: boolean

  @Prop({
    type: String,
    required: false
  }) readonly type!: "date"|"datetime-local"

  inputLabel = "";
  inputPlaceholder = "";
  inputId = "date";

  inputType = "date";

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : "";
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : "";
    if(this.id) { this.inputId = this.id }
    this.inputType = (this.type) ? this.type : "date";
  }
  
}
</script>