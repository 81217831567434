<template>
  <div class="ibox shops">
    <div class="ibox-title">
      <h2>{{ $t('shop.revenues.title') }}</h2>
    </div>
    <div class="ibox-content p-md">

      <p>{{ $t('shop.revenues.description') }}</p>

      <form role="form" @submit="onFormSubmit" name="revenues-form" class="revenues-form">
        <DateInput v-bind:value.sync="filters.startDate" :label="$t('shop.revenues.startDate')" labelAsColumn id="startDate" />
        <DateInput v-bind:value.sync="filters.endDate" :label="$t('shop.revenues.endDate')" labelAsColumn id="endDate" />
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('shop.revenues.button') }}</button>
      </form>

      <div v-if="revenues">
        <hr/>
        <h4>{{ $t('shop.revenues.revenues') }}</h4>
        <span>{{ formatPriceAmount(revenues) }}</span>
        <h4>{{ $t('shop.revenues.nbOrders') }}</h4>
        <span>{{ nbOrders }}</span>
        <h4>{{ $t('shop.revenues.averageBasket') }}</h4>
        <span>{{ formatPriceAmount(averageBasket) }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import DateInput from '@root/src/client/components/panels/input/DateInput.vue';
import ListDBs from '@root/src/client/components/panels/admin/input/ListDBs.vue';
import { authenticationTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { Price } from '@fwk-client/modules/shop/vue';

import { useRevenuesAdmin } from '../../../composables/useRevenuesAdmin';

export default defineComponent({
    props: {
    },
    components: {
        DateInput, ListDBs
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const { filters, updateRevenues, revenues, nbOrders } = useRevenuesAdmin(props, context);

        const averageBasket = computed(() => {
          if(revenues.value != undefined && nbOrders.value != undefined && nbOrders.value > 0) {
            return {
              amount: revenues.value.amount / nbOrders.value,
              currencyCode: revenues.value.currencyCode
            } as Price
          }
          return undefined;
        })

        var laddaSubmit:Ladda.LaddaButton|null = null;

        onMounted(() => {
          var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=revenues-form] button.ladda-button' );
          laddaSubmit = Ladda.create(updateDetailsButton!);
        })

        const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

        const onFormSubmit = (evt:Event) => {
          evt.preventDefault();

          laddaSubmit!.start();

          return updateRevenues().then(() => {
            laddaSubmit!.stop();
          })
        }

      return {
        hasUserRole,
        filters,
        onFormSubmit,
        revenues,
        nbOrders,
        averageBasket,
        apiRoles
      }

    }
});
</script>