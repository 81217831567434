import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useShopAdmin } from "./useShopAdmin";

interface PayoutsAdminInput {
}

export function usePayoutsAdmin(props:PayoutsAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);
  var payouts:Ref<any[]> = ref([]);

  const updateList = async () => {

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/payment/payouts');
      payouts.value = response.payouts ? response.payouts : [];
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateList();

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateList();
    },
    { deep: false }
  )

  return {
    payouts
  }
  
}